.CreditCard {
  width: 100%;
  height: 170px;
  border-radius: 10px;
  position: relative;
/*  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);*/
  overflow: hidden;
  margin: 20px auto;
}

.CreditCard-header {
  width: 100%;
  height: 50%;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 10px;
}

.CreditCard-logo {
  width: 50px;
  height: 50px;
}

.CreditCard-info {
  width: 100%;
  height: 40%;

  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
}

.CreditCard-number {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
}

.CreditCard-holder {
  font-size: 18px;
  margin-bottom: 10px;
}

.CreditCard-expiry {
  font-size: 14px;
}
